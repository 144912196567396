/* Stylesheets */
import './scss/vendors/bootstrap.scss'; // ref: https://getbootstrap.com/docs/5.2/customize/sass/#importing
// import './scss/vendors/swiper.scss'; // ref: https://swiperjs.com/get-started#installation
import './scss/vendors/hamburgers.scss'; // ref: https://jonsuh.com/hamburgers/#sass
import './scss/fonts.scss';
import './scss/style.scss';

/* Scripts */
import 'jquery';
import { } from 'bootstrap'; // ref: https://getbootstrap.com/docs/5.2/getting-started/webpack/#import-bootstrap - Point 3.
// import Swiper, { } from 'swiper'; // ref: https://swiperjs.com/get-started#install-from-npm
// import { library, dom } from '@fortawesome/fontawesome-svg-core'; // ref: https://fontawesome.com/docs/apis/javascript/import-icons#package-names
// import { fas } from '@fortawesome/pro-solid-svg-icons';
// import { far } from '@fortawesome/pro-regular-svg-icons';
// import { fal } from '@fortawesome/pro-light-svg-icons';
// import { fat } from '@fortawesome/pro-thin-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
// import { fass } from '@fortawesome/sharp-solid-svg-icons';
// library.add(fas, far, fal, fat, fad, fass);
// dom.watch();

import { library, dom } from '@fortawesome/fontawesome-svg-core' // ref: https://fontawesome.com/docs/apis/javascript/import-icons#package-names
import { faEye, faEyeSlash, faRotateRight, faAngleRight, faAngleLeft } from '@fortawesome/pro-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
library.add(faEye, faEyeSlash, faRotateRight, faAngleRight, faAngleLeft)
library.add(faFacebook, faInstagram, faTwitter, faLinkedinIn)
dom.watch()

import Cleave from 'cleave.js'
import 'cleave.js/dist/addons/cleave-phone.it'

/* Init Functions on DOMContentLoaded */
const ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
};

const isDebug = process.env.NODE_ENV !== 'production';

/* Variables and functions */
const initWebpackVersion = () => {
    isDebug && console.log(`%c${DESCRIPTION}`, `color: #9ac456; font-size: 18px; font-weight: 700;`, `Version ${VERSION} - Development Environment`);
};

let windowWidth = 0
let scrollbarWidth = 0
const activeClass = 'is-active'
const mediaMobile = window.matchMedia('(max-width: 991.98px)');
const anchorClass = 'anchor';
var min_qta_prodotto = 1;
var max_qta_prodotto = 10;
var max_num_item_prodotti = 5;
let arrayOption = [];

const calculateSidebarPadding = () => {
    const bodyHeight = document.body.offsetHeight
    if (bodyHeight > window.innerHeight) {
        let scrollDiv = document.createElement("div")
        scrollDiv = el('<div class="custom-scrollbar" style="width:50px;height:50px;overflow:scroll;"></div>')
        document.body.append(scrollDiv)
        scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
        scrollDiv.remove()
    }
}

const el = (domstring) => {
    const html = new DOMParser().parseFromString(domstring, 'text/html')
    return html.body.firstChild
}

const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

const goToAnchor = (el, offset = 0) => {
    if (typeof el === 'string') {
        el = document.querySelector(el);
    }

    if (el) {
        let anchorOffset = 0;
        const anchor = document.querySelector('.' + anchorClass)
        if (anchor && !el.classList.contains(anchorClass)) {
            const style = getComputedStyle(anchor)
            if (isNumeric(parseInt(style.top, 10))) {
                anchorOffset = parseInt(style.top, 10);
            }
        }

        const y = el.getBoundingClientRect().top + window.pageYOffset + anchorOffset - offset
        window.scrollTo({ top: y })
    }
}

const convertRemToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const ValidatorUpdateDisplay = (val) => {
    if (typeof (val.display) == "string") {
        if (val.display == "None") {
            return;
        }
        if (val.display == "Dynamic") {
            val.style.display = val.isvalid ? "none" : "block";
            ValidatorInput(val);
            return;
        }
    }
    if ((navigator.userAgent.indexOf("Mac") > -1) &&
        (navigator.userAgent.indexOf("MSIE") > -1)) {
        val.style.display = "inline";
    }
    val.style.visibility = val.isvalid ? "hidden" : "visible";
    ValidatorInput(val);
}

const ValidatorInput = (val) => {
    const elements = Array.from(val.parentNode.querySelectorAll('input, select, a'));
    const invalidClass = 'is-invalid';

    if (val.isvalid) {
        val.classList.remove(invalidClass);
    } else {
        val.classList.add(invalidClass);
    }

    const feedbacks = Array.from(val.parentNode?.querySelectorAll('.invalid-feedback'));
    const isInputInvalid = feedbacks.find(item => item.classList.contains(invalidClass));
    elements.forEach(elem => {
        isInputInvalid ? elem?.classList.add(invalidClass) : elem?.classList.remove(invalidClass);
    });
}

const ValidationSummaryOnSubmit = (validationGroup, message = null, target = null, success = false) => {
    const alertOffset = 2 //rem

    if (typeof (Page_ValidationSummaries) == "undefined")
        return;
    let summary, sums, s;
    for (sums = 0; sums < Page_ValidationSummaries.length; sums++) {
        summary = Page_ValidationSummaries[sums];
        if (!summary) continue;
        summary.style.display = "none";
        if (message && IsValidationGroupMatch(summary, validationGroup)) {
            if (summary.showsummary !== "False") {
                summary.style.display = "";
                s = message;
                summary.innerHTML = s;
                if (success) {
                    summary.classList.remove('alert-danger')
                    summary.classList.add('alert-success')
                } else {
                    summary.classList.add('alert-danger')
                    summary.classList.remove('alert-success')
                }
                if (target) {
                    goToAnchor(target, convertRemToPixels(alertOffset));
                } else {
                    goToAnchor(summary, convertRemToPixels(alertOffset));
                }
            }
        } else if (!Page_IsValid && IsValidationGroupMatch(summary, validationGroup)) {
            if (summary.showsummary !== "False" && summary.headertext) {
                summary.style.display = "";
                s = summary.headertext;
                summary.innerHTML = s;
                summary.classList.add('alert-danger')
                summary.classList.remove('alert-success')
                if (target) {
                    goToAnchor(target, convertRemToPixels(alertOffset));
                } else {
                    goToAnchor(summary, convertRemToPixels(alertOffset));
                }
            }
        }
    }
}

const popMessage = (validationGroup, message = "", target = null, success = false) => {
    ValidationSummaryOnSubmit(validationGroup, message, target, success);
}

const showLoading = (el) => {
    
    const loadingLayer = document.querySelector('.loading_form');
    if (loadingLayer) {
        loadingLayer.style.display = 'block';
    }
    
    // console.log('showloading');
    // console.log(el)
    // if (!el)
    //     return;
    //     console.log('aggiungo classe disabled');
    // el?.classList.add('disabled');

    
}

const hideLoading = () => {

    const loadingButtons = [...document.querySelectorAll('.btn-loading')]
    loadingButtons.forEach(button => {
        button.classList.remove('disabled')
    })

    const loadingLayer = document.querySelector('.loading_form')
    if (loadingLayer) {
        loadingLayer.style.display = 'none'
    }
}

const initCleave = () => { 
    const txtCAP = document.querySelector('#txtCap')
    if (txtCAP) {
        new Cleave(txtCAP, {
            blocks: [5],
            numericOnly: true
        })
    }

    const txtTelefono = document.querySelector('#txtTelefono')
    if (txtTelefono) {
        new Cleave(txtTelefono, {
            blocks: [12],
            numericOnly: true
        })
    }

    const txtData = document.querySelector('#txtDataNascita')
    if (txtData) {
        new Cleave(txtDataNascita, {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y']
        });
    }
}

const initShowPassword = () => {
    const eyes = [...document.querySelectorAll('.btn-show-password')];

    eyes.forEach(eye => {
        eye.addEventListener('click', () => {
            const password = eye.parentNode.querySelector('input');
            const type = password?.getAttribute("type") === "password" ? "text" : "password";
            if (type) {
                password.setAttribute("type", type);
                eye.querySelector('.btn-eye')?.classList.toggle('d-none');
                eye.querySelector('.btn-eye-slash')?.classList.toggle('d-none');
            }
        })
    });
}

const initMenu = () => {
    const hamburgerMenu = document.querySelector('#hamburgerMenu')
    const bsTarget = hamburgerMenu?.dataset?.bsTarget
    const bsTargetElem = document.querySelector(bsTarget)
    if (!bsTargetElem) return

    bsTargetElem?.addEventListener('show.bs.offcanvas', ev => {
        console.log('show.bs.offcanvas');
        hamburgerMenu?.classList?.add(activeClass)
        document.documentElement.style.setProperty('--fix-scrollbar-padding', `${scrollbarWidth}px`)
    })

    bsTargetElem?.addEventListener('hide.bs.offcanvas', ev => {
        console.log('hide.bs.offcanvas');
        hamburgerMenu?.classList?.remove(activeClass)
    })

    bsTargetElem?.addEventListener('hidden.bs.offcanvas', ev => {
        console.log('hidden.bs.offcanvas');
        document.documentElement.style.removeProperty('--fix-scrollbar-padding')
    })

    const menuLinks = [...document.querySelectorAll('.header__menu .nav-link.self')]
    menuLinks.forEach(link => {
        link.addEventListener('click', ev => {
            ev.preventDefault()
            // if (mediaMobile.matches) {
                hamburgerMenu.click()
                setTimeout(function () {
                    location.href = ev.target.href
                }, 400)
            // } else {
                // location.href = ev.target.href
            // }
        })
    })
}

// const initModal = () => {
//     const modals = [...document.querySelectorAll('.modal')]

//     modals.forEach(modal => {
//         modal?.addEventListener('show.bs.modal', ev => {
//             document.documentElement.style.setProperty('--fix-scrollbar-padding', `${scrollbarWidth}px`)
//         })

//         modal?.addEventListener('hidden.bs.modal', ev => {
//             document.documentElement.style.removeProperty('--fix-scrollbar-padding')
//         })
//     })
// }

const scrollFromTop = () => {
    var scrollPosition = window.scrollY;
    var logoContainer = document.getElementsByClassName('header')[0];

    if (scrollPosition > 0) {
        logoContainer.classList.add('fixed--on--scroll');
    }

    window.addEventListener('scroll', function() {

        scrollPosition = window.scrollY;

        if (scrollPosition >= 30) {
            logoContainer.classList.add('fixed--on--scroll');
        } else {
            logoContainer.classList.remove('fixed--on--scroll');
        }

    });
}


// show spinner (btn)
const showSpinner = (n) => {
    $(n).prop('disabled', true).attr('aria-disabled', true).addClass('disabled');
    $('.spinner-border', n).removeClass('d-none');
    $('.spinner-status', n).html('Attendere...');
}

// hide spinner (btn)
const hideSpinner = (n, t) => {
    $(n).prop('disabled', false).attr('aria-disabled', false).removeClass('disabled');
    $('.spinner-border', n).addClass('d-none');
    $('.spinner-status', n).html(t);
}

//Expose functions
window.ValidatorUpdateDisplay = ValidatorUpdateDisplay;
window.ValidationSummaryOnSubmit = ValidationSummaryOnSubmit;
window.showLoading = showLoading;
window.hideLoading = hideLoading;
window.popMessage = popMessage;
window.goToAnchor = goToAnchor;
window.initCleave = initCleave;
window.showSpinner = showSpinner;
window.hideSpinner = hideSpinner;
window.jQuery = $;
window.$ = $;

/* Ready callback function */
ready(() => {
    initWebpackVersion()
    calculateSidebarPadding()
    //initCleave()
    initShowPassword()
    initMenu()
    // initModal()
    scrollFromTop()
})